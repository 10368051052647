import React from "react";
import { connect } from "react-redux";
import { Creators } from "../../store/ducks/auth";
import { Redirect } from "react-router-dom";
import AuthNavbar from "components/Navbars/AuthNavbar.jsx";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col
} from "reactstrap";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: "",
      redirectToReferrer: false
    };
  }

  componentDidMount() {
    console.log(this.props);
  }

  handleSignIn = async e => {
    e.preventDefault();
    const { email, password } = this.state;
    const { authorize } = this.props;

    if (!email || !password) {
      this.setState({ error: "Preencha e-mail e senha para continuar!" });
    } else {
      try {
        authorize(email, password);
      } catch (error) {
        this.setState({
          error: "Houve um problema com o login, verifique suas credenciais."
        });
      }
    }
  };

  render() {
    const { erroPainel, error } = this.props;
    const { from } = this.props.location.state || { from: { pathname: "/" } };

    if (this.state.redirect) return <Redirect to={from} />;

    return (
      <>
        <Col lg="5" md="6">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent">
              <AuthNavbar />
            </CardHeader>

            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <h3>
                  {" "}
                  {erroPainel ? (
                    erroPainel
                  ) : (
                    <small>Entre com suas credenciais</small>
                  )}{" "}
                </h3>
              </div>
              <div className="text-center">{error && <h4>{error}!</h4>}</div>
              <Form role="form" onSubmit={this.handleSignIn}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="email"
                      value={this.state.email}
                      onChange={e => this.setState({ email: e.target.value })}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Senha"
                      type="password"
                      value={this.state.password}
                      onChange={e =>
                        this.setState({ password: e.target.value })
                      }
                    />
                  </InputGroup>
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Lembrar minha conta</span>
                  </label>
                </div>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="submit"
                    onClick={() => this.handleSignIn}
                  >
                    Entrar
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth.data,
  erroPainel: state.auth.erroPainel,
  error: state.auth.error
});

const mapDispatchToProps = dispatch => {
  return {
    authorize: (email, password) =>
      dispatch(Creators.authorize(email, password))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
