import React from "react";
import { connect } from "react-redux";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countInfo: []
    };
  }

  render() {
    return (
      <>
        <div className="header bg-default-mdb pb-8 pt-5 pt-md-4">
          <Container fluid>
            <div className="header-body">
              <Row className="justify-content-around">
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            P.A.S Registrados
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">{this.props.dashInfo.doctors}</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Novos Pacientes
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">{this.props.dashInfo.patients}</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Consultas feitas
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">{this.props.dashInfo.appointments}</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                            <i className="ni ni-ruler-pencil" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  dashInfo: state.auth.dashInfo,
});

export default connect(mapStateToProps, null)(Header);