import Index from "./views/Index.jsx";
import Payment from "./views/pages/payment/index.jsx";
import PaymentInformation from "./views/pages/payment/informationPayment";
import DoctorAvaliation from "./views/pages/doctor/doctorAvaliation.jsx";

import InformationAmbassador from "./views/pages/ambassador/informationAmbassador.jsx";
import createAmbassador from "./views/pages/ambassador/createAmbassador.jsx";
import paymentAmbassador from "./views/pages/ambassador/payment/paymentAmbassador.jsx";

import InformationRegional from "./views/pages/regional/informationRegional.jsx";
import Regional from "./views/pages/regional/informationRegional.jsx";
import CreateRegional from "./views/pages/regional/createRegional.jsx";
// import paymentRegional from "./views/pages/regional/payment/paymentRegional.jsx";

import RegionalSummary from "./views/pages/regionalSummary/index.jsx";
import InformationRegionalSummary from "./views/pages/regionalSummary/informationRegionalSummary.jsx";
import RegionalSummaryExternal from "./views/pages/regionalSummaryExternal/index.jsx";

var Routes = [
  {
    path: "/index",
    name: "Profissionais",
    icon: "fas fa-user-md text-primary",
    component: Index,
    layout: "/admin",
    isVsible: true,
  },
  {
    path: "/payments",
    name: "Pagamentos",
    icon: "fas fa-file-invoice-dollar text-primary",
    component: Payment,
    layout: "/admin",
    isVsible: true,
  },
  {
    path: "/ambassador/set",
    name: "Nomear embaixador",
    icon: "ni ni-tv-2 text-primary",
    component: createAmbassador,
    layout: "/admin",
    isVsible: false,
  },
  {
    path: "/ambassador/:id",
    name: "Embaixador",
    icon: "ni ni-single-02 text-yellow",
    component: paymentAmbassador,
    layout: "/admin",
    isVsible: false,
  },
  {
    path: "/ambassador",
    name: "Embaixador",
    icon: "fas fa-bullhorn text-primary",
    component: InformationAmbassador,
    layout: "/admin",
    isVsible: true,
  },
  {
    path: "/evaluation/:id",
    name: "Perfil",
    icon: "ni ni-single-02 text-yellow",
    component: DoctorAvaliation,
    layout: "/admin",
    isVsible: false,
  },
  {
    path: "/payment/:id",
    name: "Perfil",
    icon: "ni ni-single-02 text-yellow",
    component: PaymentInformation,
    layout: "/admin",
    isVsible: false,
  },

  // Regional ==================================================================
  {
    path: "/regional/edit/:id",
    name: "Regional",
    icon: "fas fa-globe-americas text-yellow",
    component: CreateRegional,
    layout: "/admin",
    isVsible: false,
  },
  {
    path: "/regional/:id/summary",
    name: "Resumo Regionais",
    icon: "fas fa-globe-americas text-primary",
    component: RegionalSummary,
    layout: "/admin",
    isVsible: false,
  },
  {
    path: "/regional/set",
    name: "Nomear regional",
    icon: "ni fa-globe-americas text-primary",
    component: CreateRegional,
    layout: "/admin",
    isVsible: false,
  },
  {
    path: "/regional",
    name: "Regionais",
    icon: "fas fa-globe-americas text-primary",
    component: Regional,
    layout: "/admin",
    isVsible: true,
  },

  // Regional Summary ==========================================================
  {
    path: "/details",
    name: "Relatório",
    icon: "fas fa-globe-americas text-primary",
    component: RegionalSummaryExternal,
    layout: "/regional",
    isVsible: true,
  },

  // {
  //   path: "/profile",
  //   name: "profile",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: Profile,
  //   layout: "/admin",
  //   isVsible: true,
  // }
];

export default Routes;
