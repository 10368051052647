import React from "react";
import Header from "../../../components/Headers/Header.jsx";
import { Container } from "reactstrap";
import PanelSetRegional from "./painel/PanelSetRegional.jsx";

class createRegional extends React.Component {
  state = {};

  render() {
    return (
      <>
        <Header />

        <Container className="mt--7" fluid>
          <PanelSetRegional {...this.props} />
        </Container>
      </>
    );
  }
}

export default createRegional;
