import "dotenv/config";
import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch, Redirect } from "react-router-dom";
import { Router } from "react-router-dom";
// import "./ReactotronConfig";

import Auth from "./services/client/Auth";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "assets/css/styles.css";
import { getToken } from "./services/auth";

import AdminLayout from "./layouts/Admin";
import AuthLayout from "./layouts/Auth";
import RegionalLayout from "./layouts/Regional";

import { Provider } from "react-redux";
import history from "./helpers/history";
import { store, persistor } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
// import { authorize } from "./store/sagas/auth";
// import './ReactotronConfig';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (Auth.isUserAuthenticated === getToken() ? <Component {...props} /> : <Redirect to="/auth/login" />)} />
);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <Switch>
          <PrivateRoute exact path="/admin" render={(props) => <AdminLayout {...props} />} />

          <Route
            path="/admin/index"
            render={(props) => <AdminLayout {...props} />}
            // component={Index}
          />

          <Route path="/admin/evaluation" render={(props) => <AdminLayout {...props} />} />

          <Route path="/admin/profile" render={(props) => <AdminLayout {...props} />} />

          <Route path="/admin/usuarios" render={(props) => <AdminLayout {...props} />} />

          <Route path="/admin/usuarios/:id" render={(props) => <AdminLayout {...props} />} />

          <Route path="/admin/index" render={(props) => <AdminLayout {...props} />} />

          <Route exact path="/admin/payments" render={(props) => <AdminLayout {...props} />} />

          <Route exact path="/admin/ambassador" render={(props) => <AdminLayout {...props} />} />

          <Route exact path="/admin/ambassador/set" render={(props) => <AdminLayout {...props} />} />

          <Route exact path="/admin/ambassador/:id" render={(props) => <AdminLayout {...props} />} />

          <Route path="/admin/regional/edit/:id" render={(props) => <AdminLayout pageTitle="Editar regional" {...props} />} />

          <Route exact path="/admin/regional/set" render={(props) => <AdminLayout pageTitle="Nomear regional" {...props} />} />

          <Route exact path="/admin/regional" render={(props) => <AdminLayout pageTitle="Regionais" {...props} />} />

          <Route exact path="/admin/regional/:id/summary" render={(props) => <AdminLayout pageTitle="Relatório do regional" {...props} />} />

          <Route exact path="/admin/regional-summary-external" render={(props) => <AdminLayout {...props} />} />

          <Route exact path="/admin/payment/:id" render={(props) => <AdminLayout {...props} />} />

          <Route path="/admin/funcionarios" render={(props) => <AdminLayout {...props} />} />

          <Route path="/auth/" render={(props) => <AuthLayout {...props} />} />

          <Route path="/regional/details" render={(props) => <RegionalLayout {...props} />} />

          <Redirect from="/" to="/admin/index" />
        </Switch>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
