import React from "react";
import api from "../../../../services/api";
import logo from "../../../../assets/img/theme/loading.gif";
import { Link } from "react-router-dom";
import Paginate from "react-paginate";
import { Card, Pagination, Table, Container, Row, Input, InputGroup, InputGroupAddon, Label, Button, } from "reactstrap";
import { badgeStatus } from "../../../../helpers/paymentStatus";

import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";

class tableListPayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      button: "",
      error: null,
      payments: [],
      paginate: null,
      isLoading: false,
      search: "",
      dateInitial: "",
      dateFinal: "",
      status: "",
      searchFields: ["status", "dateInitial", "dateFinal", "search"],
    };
  }

  async componentDidMount() {
    await this.getPayments();
  }

  getPayments = async (event, param = null) => {
    const page = typeof event !== "undefined" ? (event.selected += 1) : null;

    let params = { page };

    if (param) {
      params = { ...params, ...param };
    }

    this.setState({ isLoading: true });

    const { data } = await api.get("painel/payments/all", {
      params: { ...params },
    });

    console.log(data)
    this.setState({
      payments: data.data,
      paginate: data.pagination,
      isLoading: false,
    });
  };

  getStatusItem = (item) => {
    const [paymentLog, braspagPayment] = [item, item.put_request];

    let status = braspagPayment
      ? Array.isArray(braspagPayment)
        ? 10
        : braspagPayment.hasOwnProperty("Status")
        ? braspagPayment.Status
        : braspagPayment.Payment.Status
      : 0;

    if (paymentLog.status) status = paymentLog.status;

    return <span className={`badge badge-${badgeStatus(status).badge} font-size-badge`}>{badgeStatus(status).label}</span>;
  };

  csvGenerator = async () => {
    const { dateInitial, dateFinal } = this.state;
    const { data } = await api.post("painel/payments/export", { dateInitial, dateFinal });
    window.open(`${data.url}`, "_blank");
  };

  Payments = () => {
    const { payments, isLoading } = this.state;

    if (isLoading) {
      return (
        <tr>
          <td>
            <span className="w-100">
              <img src={logo} width="50px" alt="loading..." />
              <span className="m-4">Carregando registros... </span>
            </span>
          </td>
        </tr>
      );
    }

    return payments.length ? (
      payments.map((item) => {
        return (
          <tr key={item.id}>
            <td>
              <div className="d-flex align-items-center">
                <span className="mr-2">{item.doctor ? (item.doctor.name ? item.doctor.name : "") : "Médico não registrado."}</span>
              </div>
            </td>
            <td>
              <div className="d-flex align-items-center">
                <span className="mr-2">{item.patient ? item.patient.name : ""}</span>
              </div>
            </td>
            <td>
              <div className="d-flex align-items-center">{this.getStatusItem(item)}</div>
            </td>
            <td>
              <div className="d-flex align-items-center">
                <span className="mr-2">{item.patient ? item.patient.email : ""}</span>
              </div>
            </td>
            <td>
              <div className="d-flex align-items-center">
                <span className="mr-2">{item.patient ? item.patient.cpf : ""}</span>
              </div>
            </td>
            <td>
              <div className="d-flex align-items-center">
                <span className="mr-2">{item.created_at}</span>
              </div>
            </td>
            <td className="text-right">
              <div>
                <Link
                  className="btn btn-success"
                  to={{
                    pathname: `/admin/payment/${item.id}`,
                  }}
                >
                  Visualizar
                </Link>
              </div>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td>
          <span className="w-100">
            <span className="m-4"> Não há registro </span>
          </span>
        </td>
      </tr>
    );
  };

  Paginate = () => {
    const { paginate } = this.state;

    if (paginate === null) return <> </>;

    const { totalPages } = paginate;

    return (
      //Pagination Pagamentos
      <Pagination className="justify-content-center d-flex mt-3">
        <div className="d-flex ul-in-line">
          <Paginate
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={this.search}
            previousLabel="Anterior"
            nextLabel="Proximo"
            activeClassName="ul-li-active"
          />
        </div>
      </Pagination>
    );
  };

  search = async (e) => {
    const { searchFields } = this.state;

    let params = {};

    await searchFields.map((item) => {
      if (this.state[item] !== "") {
        params[item] = this.state[item];
        return params[item];
      }
      return null;

    });

    return this.getPayments(e, { ...params });
  };

  render() {
    return (
      <>
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <div className="d-flex justify-content-between flex-row align-items-end">
                  <InputGroup style={{ padding: 10 }}>
                    <InputGroupAddon addonType="prepend">Pesquisa</InputGroupAddon>
                    <Input value={this.state.search} onChange={(e) => this.setState({ search: e.target.value })} />
                    <InputGroupAddon addonType="prepend">
                      <Button color="primary" type="button" onClick={() => this.search()}>
                        <i className="fas fa-search"></i>
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>

                  <div className="d-flex flex-column w-100" style={{ padding: 7 }}>
                    <Label for="filterStore">Status do Pagamento</Label>

                    <Input
                      type="select"
                      name="select"
                      id="filterStore"
                      onChange={async (e) => {
                        await this.setState({ status: e.target.value });

                        this.search();
                      }}
                    >
                      <option value="">Todos</option>
                      <option value="2">Aprovado</option>
                      <option value="13">Falha no processamento</option>
                      <option value="10">Cancelado</option>
                    </Input>
                  </div>

                  <div className="d-flex flex-column w-100" style={{ padding: 7 }}>
                    <div className="d-flex flex-row justify-content-between mb-2">
                      <Label for="date">Data</Label>
                      <div>
                        <Button
                          color="success"
                          type="button"
                          className="btn-sm btn-outline-success"
                          onClick={async () => {
                            this.csvGenerator();
                          }}
                        >
                          <span> Exportar </span>
                          <i className="fas fa-download"></i>
                        </Button>
                        <Button
                          color="primary"
                          type="button"
                          className="btn-sm btn-outline-primary"
                          onClick={async () => {
                            await this.setState({ dateInitial: "" });

                            this.search();
                          }}
                        >
                          <span> Limpar data </span>
                        </Button>
                      </div>
                    </div>

                    <div className="inputs-date">
                      <Input
                        type="date"
                        id="dateInitial"
                        className="datepicker input-date-payments"
                        value={this.state.dateInitial}
                        onChange={async (e) => {
                          await this.setState({ dateInitial: e.target.value });

                          this.search();
                        }}
                      />
                      <Input
                        type="date"
                        id="dateFinal"
                        className="datepicker input-date-payments"
                        value={this.state.dateFinal}
                        onChange={async (e) => {
                          await this.setState({ dateFinal: e.target.value });

                          this.search();
                        }}
                      />
                    </div>
                  </div>
                </div>

                <Table className="align-items-center table-hover" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Médico</th>

                      <th scope="col">Paciente</th>

                      <th scope="col">Status</th>

                      <th scope="col">E-mail</th>

                      <th scope="col">CPF</th>

                      <th scope="col">Data</th>

                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>{this.Payments()}</tbody>
                </Table>
                <nav>{this.Paginate()}</nav>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default tableListPayments;
