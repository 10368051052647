import React from "react";
import api from "../../../../services/api";
import logo from "../../../../assets/img/theme/loading.gif";
// import { Link } from "react-router-dom";
import Paginate from "react-paginate";
import {
  Card,
  Pagination,
  Table,
  Container,
  Row,
  Input,
  InputGroup,
  // InputGroupAddon,
  // Label,
  Button,
} from "reactstrap";
import { connect } from "react-redux";

import moment from "moment";

import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";

class TableListRegionalsSummaryExternal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      button: "",
      error: null,
      regionalsSummary: [],
      paginate: null,
      isLoading: false,
      dateInitial: "",
      status: "",
      doctor_name: "",
      cpf: "",
      searchFields: ["doctor_name", "cpf", "dateInitial"],
      dateInitial: "",
      month: "",
      year: "",
      regionalId: null,
    };
  }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props;

    const date = new Date();

    await this.setState({ month: moment(date).format("MM"), year: moment(date).format("YYYY") });
    await this.getRegionalsSummary();
    // this.search();
  }

  search = async (e) => {
    const { searchFields } = this.state;

    let params = {};

    await searchFields.map((item) => {
      if (this.state[item] !== "") {
        params[item] = this.state[item];
        console.log(params[item]);
        return params[item];
      }

      return null;
    });

    return this.getRegionalsSummary(e, null);
  };

  getRegionalsSummary = async (event, param = null) => {
    // const page = typeof event !== "undefined" ? (event.selected += 1) : null;
    const { month, year } = this.state;
    const { id } = this.props.regional;

    let params = { month, year };

    if (param) {
      params = { ...params, ...param };
    }

    this.setState({ isLoading: true });

    const { data } = await api.get("regional/regional/summaries/" + id, {
      params: { ...params },
    });

    this.setState({
      regionalsSummary: data.data,
      isLoading: false,
    });
  };

  csvGenerator = async () => {
    const { doctor_name, cpf, dateInitial } = this.state;
    const { data } = await api.post("/regional-summary/export", {
      doctor_name,
      cpf,
      dateInitial,
    });

    window.open(`${data.url}`, "_blank");
  };

  RegionalsSummary = () => {
    const { regionalsSummary, isLoading } = this.state;

    if (isLoading) {
      return (
        <tr>
          <td>
            <span className="w-100">
              <img src={logo} width="50px" alt="loading..." />
              <span className="m-4">Carregando registros... </span>
            </span>
          </td>
        </tr>
      );
    }

    return regionalsSummary.length ? (
      regionalsSummary.map((item) => {
        let date = moment(item.date).format("DD/MM/YYYY");

        return (
          <tr key={item.id}>
            <td className="text-center">
              <div>
                <span className="mr-2">{date.toLocaleString()}</span>
              </div>
            </td>
            <td className="text-right">
              <div>
                <span className="mr-2">{item.number_doctors}</span>
              </div>
            </td>
            <td className="text-right">
              <div>
                <span className="mr-2">{item.number_appointment}</span>
              </div>
            </td>
            <td className="text-right">
              <div>
                <span className="mr-2">
                  {Number(item.number_appointment).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </span>
              </div>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td>
          <span className="w-100">
            <span className="m-4"> Não há registro com os dados informados </span>
          </span>
        </td>
      </tr>
    );
  };

  RegionalsSummaryTotal = () => {
    const { regionalsSummary } = this.state;

    let totalAppointments = 0;
    let totalDoctors = 0;
    let totalToReceive = 0;

    if (regionalsSummary.length) {
      regionalsSummary.map((item) => {
        totalAppointments = totalAppointments + Number(item.number_appointment);
        // totalDoctors = totalDoctors + Number(item.number_doctors);
        totalToReceive = totalToReceive + Number(item.to_receive);

        return null;
      });

      totalDoctors = regionalsSummary.reverse()[0].number_doctors;

      return (
        <tr key={1}>
          <th className="text-center">
            <strong>Total no mês</strong>
          </th>

          <th className="text-right">
            <div>
              <span>
                <strong>{totalDoctors}</strong>
              </span>
            </div>
          </th>

          <th className="text-right">
            <div>
              <span>
                <strong>{totalAppointments}</strong>
              </span>
            </div>
          </th>

          <th className="text-right">
            <div>
              <span>
                <strong>
                  {totalToReceive.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </strong>
              </span>
            </div>
          </th>
        </tr>
      );
    } else {
      return (
        <tr>
          <td>
            <span className="w-100">
              <span className="m-4"> </span>
            </span>
          </td>
        </tr>
      );
    }
  };

  Paginate = () => {
    const { paginate } = this.state;

    if (paginate === null) return <> </>;

    const { totalPages } = paginate;

    return (
      //Pagination Pagamentos
      <Pagination className="justify-content-center d-flex mt-3">
        <div className="d-flex ul-in-line">
          <Paginate
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={this.search}
            previousLabel="Anterior"
            nextLabel="Proximo"
            activeClassName="ul-li-active"
          />
        </div>
      </Pagination>
    );
  };

  render() {
    return (
      <>
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <div className="d-flex flex-row justify-content-between align-items-end">
                  {/* <InputGroup id="pesquisa" style={{ padding: 10 }}>
                    <Input
                      title="Informe o Nome ou parte do Nome do médico"
                      value={this.state.doctor_name}
                      placeholder="Nome"
                      onChange={(e) => this.setState({ doctor_name: e.target.value })}
                    />
                  </InputGroup> */}

                  {/* <InputGroup style={{ padding: 10 }}>
                    <InputGroupAddon addonType="prepend">
                      Pesquisa
                    </InputGroupAddon>
                    <Input
                      value={this.state.search}
                      onChange={(e) =>
                        this.setState({ search: e.target.value })
                      }
                    />
                    <InputGroupAddon addonType="prepend">
                      <Button
                        color="primary"
                        type="button"
                        onClick={() => this.search()}
                      >
                        <i className="fas fa-search"></i>
                      </Button>
                    </InputGroupAddon>
                  </InputGroup> */}

                  {/* <InputGroup style={{ padding: 10 }}> */}
                  {/* <InputGroupAddon addonType="prepend">
                      CPF
                    </InputGroupAddon> */}
                  {/* <Input
                      title="Informe o CPF ou parto do CPF"
                      placeholder="CPF"
                      value={this.state.cpf}
                      onChange={(e) => this.setState({ cpf: e.target.value })}
                    /> */}
                  {/* <InputGroupAddon addonType="prepend">
                      <Button
                        color="primary"
                        type="button"
                        onClick={() => this.search()}
                      >
                        <i className="fas fa-search"></i>
                      </Button>
                    </InputGroupAddon> */}
                  {/* </InputGroup> */}

                  {/* <div
                    className="d-flex flex-column w-100"
                    style={{ padding: 7 }}
                  >
                    <Label for="filterStore">Status do Pagamento</Label>

                    <Input
                      type="select"
                      name="select"
                      id="filterStore"
                      onChange={async (e) => {
                        await this.setState({ status: e.target.value });

                        this.search();
                      }}
                    >
                      <option value="">Todos</option>
                      <option value="2">Aprovado</option>
                      <option value="13">Falha no processamento</option>
                      <option value="10">Cancelado</option>
                    </Input>
                  </div> */}

                  <div className="d-flex flex-column w-100" style={{ padding: 7 }}>
                    {/* <div className="d-flex flex-row justify-content-end mb-2">
                      <div>
                        <Button
                          title="Exporta para aquivo"
                          color="success"
                          type="button"
                          className="btn-sm btn-outline-success"
                          onClick={async () => {
                            this.csvGenerator();
                          }}
                        >
                          <span> Exportar </span>
                          <i className="fas fa-download"></i>
                        </Button>
                        <Button
                          color="primary"
                          type="button"
                          className="btn-sm btn-outline-primary"
                          onClick={async () => {
                            await this.setState({ dateInitial: "" });

                            this.search();
                          }}
                        >
                          <span> Recalcular </span>
                        </Button>
                      </div>
                    </div> */}

                    <div className="inputs-date">
                      <Input
                        title="Informe o mês e o ano para pesquisar"
                        width="100px"
                        type="month"
                        id="dateInitial"
                        className="datepicker input-date-payments"
                        value={this.state.dateInitial}
                        onChange={async (e) => {
                          const date = e.target.value;
                          await this.setState({ dateInitial: date, year: date.split("-")[0], month: date.split("-")[1] });

                          this.search();
                        }}
                      />
                      <Button title="Clique para pesquisar" color="primary" type="button" onClick={() => this.search()}>
                        <i className="fas fa-search"></i>
                      </Button>
                    </div>
                  </div>
                </div>

                <Table className="align-items-center table-hover  " responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" className="text-center">
                        Data
                      </th>
                      <th scope="col" className="text-center">
                        Médicos cadastrados
                      </th>
                      <th scope="col" className="text-center">
                        Consultas realizadas
                      </th>
                      <th scope="col" className="text-right">
                        Total à receber
                      </th>
                    </tr>
                  </thead>
                  <tbody>{this.RegionalsSummary()}</tbody>
                  <tfoot className="thead-light">{this.RegionalsSummaryTotal()}</tfoot>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  regional: state.auth.data,
});

export default connect(mapStateToProps, null)(TableListRegionalsSummaryExternal);
