import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import ducks from "./ducks";
import sagas from "./sagas";
import { routerMiddleware } from "connected-react-router";
import history from "../helpers/history";

const persistConfig = {
  key: "root",
  storage
};

const persistedReducer = persistReducer(persistConfig, ducks);

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, routerMiddleware(history)];

const composer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  persistedReducer,
  composer(applyMiddleware(...middlewares))
);

const persistor = persistStore(store);

sagaMiddleware.run(sagas);

export { store, persistor };
