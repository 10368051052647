import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

// reactstrap components
import { Container } from "reactstrap";
import { connect } from "react-redux";

// core components
import AdminNavbar from "../components/Navbars/AdminNavbar.jsx";
import AdminFooter from "../components/Footers/AdminFooter";
import Sidebar from "../components/Sidebar/Sidebar.jsx";

import routes from "../routes";

class Admin extends React.Component {
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // this.refs.mainContent.scrollTop = 0;
  }

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
      }

      return null;
    });
  };

  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (this.props.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }

    return "Brand";
  };
  render() {
    const token = localStorage.getItem("token");
    const entity = localStorage.getItem("entity");
    const { pageTitle } = this.props;

    if (!token) return <Redirect to="/auth/login" />;

    if (entity !== "doctor") return <Redirect to="/regional/details" />;

    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes.filter((route) => route.layout === "/admin")}
          logo={{
            innerLink: "/admin/index",
            imgSrc: require("assets/img/brand/argon-react.png"),
            imgAlt: "...",
          }}
        />

        <div className="main-content" ref="mainContent">
          <AdminNavbar {...this.props} brandText={pageTitle || this.getBrandText(this.props.location.pathname)} />

          <Switch>{this.getRoutes(routes)}</Switch>

          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps, {})(Admin);
// export default Admin;
