import React from "react";
import { Container } from "reactstrap";
import Header from "components/Headers/Header.jsx";
import TableListRegionalsSummaryExternal from "../regionalSummaryExternal/table/tableListRegionalsSummary.jsx";

class Index extends React.Component {
  render() {
    return (
      <>
        <Container className="regional-summary-external" fluid>
          <TableListRegionalsSummaryExternal {...this.props} />
        </Container>
      </>
    );
  }
}

export default Index;
