import React from "react";
import logo from "../../../assets/img/theme/loading.gif";
import api from "../../../services/api";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  ButtonGroup,
  Button,
} from "reactstrap";
import classnames from "classnames";
import moment from "moment";
import {
  getMessageStatus,
  getMessageStatusFraude,
  badgeStatus,
  STATUS_VOIDED,
  STATUS_REFUNDED,
} from "../../../helpers/paymentStatus";
import PaymentModal from "./modal/PaymentModal";
import UserHeader from "../../../components/Headers/UserHeader.jsx";

class informationRegionalSummary extends React.Component {
  state = {
    name: "",
    last_name: "",
    cpf: "",
    email: "",
    estado: "",
    cidade: "",
    error: "",
    doctor: {},
    isModalOpen: false,
    labelModal: "",
    status: 10,
    denialreason: "",
    address: {},
    isLoading: false,
    tabs: 1,
    payment: {},
    patientPayment: {},
    braspagPayment: {},
  };

  componentDidMount() {
    const {
      match: { params },
    } = this.props;

    this.getInfoPayment(params.id);
  }

  toggleNavs = (e, state, index) => {
    e.preventDefault();

    this.setState({
      [state]: index,
    });
  };

  getInfoPayment = async (id) => {
    this.toggleLoading();

    const { data } = await api.get(`/payment-details/${id}`);

    this.getActualStatusPayment(data);

    this.setState({
      payment: data.data,
      patientPayment: data.data.send_request,
      braspagPayment:
        data.data.put_request !== undefined ? data.data.put_request : "",
      address:
        data.data.send_request !== ""
          ? data.data.send_request.Customer.Address === undefined
            ? data.data.send_request.Customer.address !== undefined
              ? data.data.send_request.Customer.address
              : {}
            : data.data.send_request.Customer.Address
          : {},
      isLoading: false,
    });
    console.log(data);
  };

  getActualStatusPayment = ({ data }) => {
    const [paymentLog, braspagPayment] = [data, data.put_request];
    if (braspagPayment === "") return;
    let status = parseInt(braspagPayment.Payment.Status);

    if (paymentLog.status) status = parseInt(paymentLog.status);

    this.setState({ status });
  };

  numberToReal = (number) => {
    var numberReal = number.toFixed(2).split(".");
    numberReal[0] = "R$ " + numberReal[0].split(/(?=(?:...)*$)/).join(".");
    return numberReal.join(",");
  };

  toggleLoading = async () => {
    this.setState({ isLoading: !this.state.isLoading });
  };

  loading = () => (
    <div className="w-100 d-flex justify-content-center">
      <img src={logo} width="50px" alt="loading..." />
      <span className="m-4"> Carregando registros... </span>
    </div>
  );

  toggleModal = () => this.setState({ isModalOpen: !this.state.isModalOpen });

  render() {
    const {
      address,
      doctor,
      isLoading,
      patientPayment,
      braspagPayment,
      isModalOpen,
      status,
    } = this.state;

    const {
      match: { params },
    } = this.props;

    return (
      <>
        <PaymentModal
          isOpen={isModalOpen}
          toggle={() => this.toggleModal()}
          braspagPayment={this.state.braspagPayment}
          updateInfoPaymnet={(id) => this.getInfoPayment(id)}
          paymnetId={params.id}
        />

        <UserHeader doctor={doctor} />

        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="justify-content-center mt-5 container-fluid">
            <Col className="order-xl-2">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0 p-3">
                  <div className="nav-wrapper">
                    <div className="p-2">
                      <Row className="align-items-center">
                        <h4>Status atual do pagamento: </h4>
                        <div className="d-flex align-items-center mb-2 ml-2">
                          <span
                            className={`badge badge-${
                              badgeStatus(status).badge
                            } font-size-badge`}
                          >
                            {badgeStatus(status).label}
                          </span>
                        </div>
                      </Row>
                    </div>
                    <Nav
                      className="nav-fill flex-column flex-md-row"
                      id="tabs-icons-text"
                      pills
                      role="tablist"
                    >
                      <NavItem>
                        <NavLink
                          aria-selected={this.state.tabs === 1}
                          className={classnames("mb-sm-3 mb-md-0", {
                            active: this.state.tabs === 1,
                          })}
                          onClick={(e) => this.toggleNavs(e, "tabs", 1)}
                          href="#pablo"
                          role="tab"
                        >
                          <i className="ni ni-cloud-upload-96 mr-2" />
                          Dados Enviados
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          aria-selected={this.state.tabs === 2}
                          className={classnames("mb-sm-3 mb-md-0", {
                            active: this.state.tabs === 2,
                          })}
                          onClick={(e) => this.toggleNavs(e, "tabs", 2)}
                          href="#pablo"
                          role="tab"
                        >
                          <i className="ni ni-cloud-download-95 mr-2" />
                          Dados Braspag
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </CardHeader>
                <CardBody className="p-2">
                  {isLoading ? (
                    this.loading()
                  ) : (
                    <>
                      <TabContent activeTab={"tabs" + this.state.tabs}>
                        <TabPane tabId="tabs1">
                          <div className="pl-lg-4">
                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label font-size-label">
                                    Nome:
                                  </label>
                                  <span className="mb-0">
                                    {" "}
                                    {patientPayment.hasOwnProperty("Customer")
                                      ? patientPayment.Customer.Name ===
                                        undefined
                                        ? patientPayment.Customer.name
                                        : patientPayment.Customer.Name
                                      : ""}
                                  </span>
                                </FormGroup>

                                <FormGroup>
                                  <label
                                    className="form-control-label font-size-label"
                                    htmlFor="input-email"
                                  >
                                    E-mail:
                                  </label>
                                  <span className="mb-0">
                                    {" "}
                                    {patientPayment.hasOwnProperty("Customer")
                                      ? patientPayment.Customer.Email ===
                                        undefined
                                        ? patientPayment.Customer.email
                                        : patientPayment.Customer.email
                                      : ""}
                                  </span>
                                </FormGroup>

                                <FormGroup>
                                  <label
                                    className="form-control-label font-size-label"
                                    htmlFor="input-email"
                                  >
                                    Cpf:
                                  </label>
                                  <span className="mb-0">
                                    {" "}
                                    {patientPayment.hasOwnProperty("Customer")
                                      ? patientPayment.Customer.Identity ===
                                        undefined
                                        ? patientPayment.Customer.identity
                                        : patientPayment.Customer.identity
                                      : ""}
                                  </span>
                                </FormGroup>

                                <FormGroup>
                                  <label
                                    className="form-control-label font-size-label"
                                    htmlFor="input-email"
                                  >
                                    Data nascimento:
                                  </label>
                                  <span className="mb-0">
                                    {" "}
                                    {patientPayment.hasOwnProperty("Customer")
                                      ? patientPayment.Customer.BirthDate ===
                                        undefined
                                        ? moment(
                                            patientPayment.Customer.birthDate,
                                            "YYYY-MM-DD"
                                          ).format("DD/MM/YYYY")
                                        : moment(
                                            patientPayment.Customer.BirthDate,
                                            "YYYY-MM-DD"
                                          ).format("DD/MM/YYYY")
                                      : ""}
                                  </span>
                                </FormGroup>

                                <FormGroup>
                                  <label className="form-control-label font-size-label">
                                    Rua:
                                  </label>
                                  <span className="mb-0">
                                    {" "}
                                    {address.hasOwnProperty("Street")
                                      ? address.Street
                                      : address.hasOwnProperty("street")
                                      ? address.street
                                      : ""}
                                  </span>
                                </FormGroup>

                                <FormGroup>
                                  {" "}
                                  <label className="form-control-label font-size-label">
                                    Cidade:
                                  </label>
                                  <span className="mb-0">
                                    {" "}
                                    {address.hasOwnProperty("City")
                                      ? address.City
                                      : address.hasOwnProperty("city")
                                      ? address.city
                                      : ""}
                                  </span>
                                </FormGroup>

                                <FormGroup>
                                  {" "}
                                  <label className="form-control-label font-size-label">
                                    Estado:
                                  </label>
                                  <span className="mb-0">
                                    {" "}
                                    {address.hasOwnProperty("State")
                                      ? address.State
                                      : address.hasOwnProperty("state")
                                      ? address.state
                                      : ""}
                                  </span>
                                </FormGroup>
                              </Col>

                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label font-size-label">
                                    Número do cartão:
                                  </label>
                                  <span className="mb-0">
                                    {" "}
                                    {braspagPayment.hasOwnProperty("Payment")
                                      ? braspagPayment.Payment.CreditCard
                                          .CardNumber
                                      : ""}
                                  </span>
                                </FormGroup>

                                <FormGroup>
                                  <label className="form-control-label font-size-label">
                                    Nome do cartão:
                                  </label>
                                  <span className="mb-0">
                                    {" "}
                                    {braspagPayment.hasOwnProperty("Payment")
                                      ? braspagPayment.Payment.CreditCard.Holder
                                      : ""}
                                  </span>
                                </FormGroup>

                                <FormGroup>
                                  <label className="form-control-label font-size-label">
                                    Data do cartão:
                                  </label>
                                  <span className="mb-0">
                                    {" "}
                                    {braspagPayment.hasOwnProperty("Payment")
                                      ? braspagPayment.Payment.CreditCard
                                          .ExpirationDate
                                      : ""}
                                  </span>
                                </FormGroup>

                                <FormGroup>
                                  <label
                                    className="form-control-label font-size-label"
                                    htmlFor="input-email"
                                  >
                                    Tipo de trasação:
                                  </label>
                                  <span className="mb-0">
                                    {" "}
                                    {patientPayment.hasOwnProperty("Payment")
                                      ? patientPayment.Payment.Type ===
                                        "SplittedCreditCard"
                                        ? "Crédito"
                                        : "Débito"
                                      : ""}
                                  </span>
                                </FormGroup>

                                <FormGroup>
                                  <label
                                    className="form-control-label font-size-label"
                                    htmlFor="input-email"
                                  >
                                    Split de pagamento:
                                  </label>
                                  <span className="mb-0">
                                    {" "}
                                    {patientPayment.hasOwnProperty("Payment")
                                      ? patientPayment.Payment.isSplitted
                                        ? "Sim"
                                        : "Não"
                                      : ""}
                                  </span>
                                </FormGroup>

                                <FormGroup>
                                  <label className="form-control-label font-size-label">
                                    Valor:
                                  </label>
                                  <span className="mb-0">
                                    {" "}
                                    {patientPayment.hasOwnProperty("Payment")
                                      ? patientPayment.Payment.Amount ===
                                        undefined
                                        ? this.numberToReal(
                                            patientPayment.Payment.amount / 100
                                          )
                                        : this.numberToReal(
                                            patientPayment.Payment.Amount / 100
                                          )
                                      : ""}
                                  </span>
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        </TabPane>

                        <TabPane tabId="tabs2">
                          <div className="pl-lg-4">
                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <label className="form-control-label font-size-label">
                                    Identificador (id):
                                  </label>
                                  <span className="mb-0">
                                    {" "}
                                    {braspagPayment.hasOwnProperty("Payment")
                                      ? braspagPayment.Payment.PaymentId
                                      : ""}
                                  </span>
                                </FormGroup>

                                <FormGroup>
                                  <label className="form-control-label font-size-label">
                                    Status:
                                  </label>
                                  <span className="mb-0">
                                    {" "}
                                    {braspagPayment.hasOwnProperty("Payment")
                                      ? getMessageStatus(
                                          braspagPayment.Payment.Status
                                        )
                                      : ""}
                                  </span>
                                </FormGroup>

                                <FormGroup>
                                  <label className="form-control-label font-size-label">
                                    Parcelamento:
                                  </label>
                                  <span className="mb-0">
                                    {" "}
                                    {braspagPayment.hasOwnProperty("Payment")
                                      ? braspagPayment.Payment.Installments
                                      : ""}
                                  </span>
                                </FormGroup>

                                <FormGroup>
                                  <label className="form-control-label font-size-label">
                                    Status Antifraude:
                                  </label>
                                  <span className="mb-0">
                                    {" "}
                                    {braspagPayment.hasOwnProperty("Payment")
                                      ? braspagPayment.Payment.FraudAnalysis
                                          .StatusDescription
                                      : ""}
                                  </span>
                                </FormGroup>
                                {braspagPayment.hasOwnProperty("Payment")
                                  ? braspagPayment.Payment.FraudAnalysis
                                      .Status === 2 && (
                                      <FormGroup>
                                        <label className="form-control-label font-size-label">
                                          Motivo:
                                        </label>
                                        <span className="mb-0">
                                          {getMessageStatusFraude(
                                            braspagPayment.Payment.FraudAnalysis
                                          )}
                                        </span>
                                      </FormGroup>
                                    )
                                  : null}
                              </Col>
                            </Row>
                          </div>
                        </TabPane>
                      </TabContent>
                    </>
                  )}

                  <div className="d-flex justify-content-center">
                    <ButtonGroup size="lg">
                      <Button
                        type="button"
                        className="btn btn-outline-* btn-danger"
                        onClick={this.toggleModal}
                        disabled={
                          status === STATUS_VOIDED || status === STATUS_REFUNDED
                        }
                      >
                        Cancelar Pagamento
                      </Button>
                    </ButtonGroup>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default informationRegionalSummary;
