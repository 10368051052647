import React from "react";
import { Link } from "react-router-dom";
// import { logout } from "../../services/auth";
import { Creators } from "../../store/ducks/logout";
import { connect } from "react-redux";

// reactstrap components
import { DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Button, Navbar, Nav, Container, Media } from "reactstrap";

class AdminNavbar extends React.Component {
  handleSignOut = () => this.props.logout();

  render() {
    const { auth } = this.props;

    // const avatar = `https://mdbhomolog.soterotech.net/documents/${auth.data.avatar || "default_avatar.png"}`;
    const avatar = process.env.REACT_APP_BASE_URL_API + `/documents/${auth.data.avatar || "default_avatar.png"}`;

    // console.log(avatar);

    return (
      <Navbar className="navbar-top navbar-dark col-md-12 col-lg-12 ml-auto bg-default-mdb" expand="md" id="navbar-main" style={{ position: "initial" }}>
        <Container fluid>
          <Link className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block" to="/">
            <h1 className="text-white">{this.props.brandText}</h1>
          </Link>

          <Nav className="align-items-center d-md-flex auth-user" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <Media className="ml-2 mr-3">
                    <span className="mb-0 text-sm  font-weight-bold">
                      <h2 className="text-white">{auth.data.presentation_name}</h2>
                    </span>
                  </Media>
                  <span className="avatar avatar-sm rounded-circle">
                    <img alt="Minha foto de perfil" src={avatar} />
                  </span>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Bem vindo!</h6>
                </DropdownItem>
                <DropdownItem divider />
                <div className="justify-content-center text-center">
                  <Button color="danger" size="xl" onClick={this.handleSignOut}>
                    Sair <i className="ni ni-user-run 2x" />
                  </Button>
                </div>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
  user: state.auth.data,
});

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (token) => dispatch(Creators.logout(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);
