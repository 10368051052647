import React from "react";
import logo from "../../../../assets/img/theme/loading.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import ModalDoctor from "../modal/ModalDoctor";
import api from "../../../../services/api";
import { Card, Pagination, PaginationItem, PaginationLink, Table, Container, Row, Button, Badge, Input } from "reactstrap";

class tableListCertificates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      button: "",
      error: null,
      certificates: [],
      paginate: null,
      isModalOpen: false,
      labelModal: "",
      status: "",
      denialreason: "",
      certificateid: "",
      doctorId: "",
      isLoading: false,
    };
  }

  dowloadImage = (path, name) => {
    const nameImg = name.split("/")[3];
    let element = document.createElement("a");
    const file = new Blob([path], { type: "image/*" });
    element.href = URL.createObjectURL(file);
    element.download = `${nameImg}`;
    document.body.appendChild(element);
  };

  handleSubmit = async () => {
    const { status, denialreason, certificateid } = this.state;
    const { doctor } = this.props;

    this.toggleLoading();

    await api.post(`doctors/notification/certificate`, {
      status,
      denialreason,
      certificateid,
      doctorId: doctor.id,
    });

    await this.props.getCertificate(doctor.id);

    this.toggleModal();

    this.toggleLoading();
  };

  certificates = (certificates) => {
    const { isLoading } = this.state;

    if (isLoading) return this.loading();

    if (typeof certificates !== "undefined") {
      return certificates.length ? (
        certificates.map((item) => {
          return (
            <tr key={item.id}>
              <th scope="row">
                <div className="d-flex align-items-center">
                  <span className="mr-2">{item.specialty}</span>
                </div>
              </th>
              <td>
                <div className="d-flex align-items-center">
                  <span className={`${item.badges} font-size-badge`}>{item.label_status}</span>
                </div>
              </td>

              
              <td>
                <div className="d-flex align-items-center d-flex justify-content-around">
                  {item.path ? (
                    <>
                      <img src={ process.env.REACT_APP_BASE_URL_API + `/documents/${item.path}`} alt="" height="65" width="70" className="border-radius"  />

                      <a href={ process.env.REACT_APP_BASE_URL_API + `/documents/${item.path}`} download={item.path.split("/")[3]} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faDownload} size="2x" />
                      </a>
                    </>
                  ) : (
                    "Não possui path."
                  )}
                </div>
              </td>

              <td className="text-center">
                <div>
                  <Button className="btn btn-success" onClick={() => this.toggleModalContext("Aprov", "Aprovação", "3", item)}>
                    Aprovar
                  </Button>

                  <Button className="btn btn-warning" onClick={() => this.toggleModalContext("Reprov", "Motivo Reprovação", "2", item)}>
                    Reprovar
                  </Button>
                </div>
              </td>
            </tr>
          );
        })
      ) : (
        <tr className="p-5 text-center">
          <span className="w-100">
            <span className="m-4">Não possui documentos. </span>
          </span>
        </tr>
      );
    }

    return this.loading();
  };

  loading = () => (
    <tr>
      <td>
        <span className="w-100">
          <img src={logo} width="50px" alt="loading..." />
          <span className="m-4"> Carregando registros... </span>
        </span>
      </td>
    </tr>
  );

  paginate = () => {
    const { paginate } = this.state;

    if (paginate === null) return <> </>;

    const { totalPages, currentPage } = paginate;

    let pageItem = [];
    let nextPage = currentPage === totalPages ? 0 : currentPage + 1;
    let previusPage = currentPage !== 1 ? currentPage - 1 : 0;

    for (let i = 0; i < paginate.totalPages; i++) {
      let indece = i + 1;

      pageItem.push(
        <PaginationItem key={Math.random() * i} className={i === currentPage - 1 ? "active" : "none"}>
          <PaginationLink onClick={(event) => this.getDoctors(event)} value={indece}>
            {indece}
          </PaginationLink>
        </PaginationItem>
      );
    }

    return (
      <Pagination className="justify-content-center d-flex mt-3">
        <div className="d-flex">
          <PaginationItem className={previusPage === 0 ? "disabled" : ""}>
            <PaginationLink onClick={(event) => this.getDoctors(event)} tabIndex="-1" value={previusPage}>
              <i className="fa fa-angle-left" />
              <span className="sr-only">Anterior</span>
            </PaginationLink>
          </PaginationItem>

          {pageItem}

          <PaginationItem className={nextPage === 0 ? "disabled" : ""}>
            <PaginationLink onClick={(event) => this.getDoctors(event)} value={nextPage}>
              <i className="fa fa-angle-right" />
              <span className="sr-only"> Proximo </span>
            </PaginationLink>
          </PaginationItem>
        </div>
      </Pagination>
    );
  };

  toggleModalContext = (actual, label, status, certificate) => {
    const { isModalOpen } = this.state;

    this.setState({
      isModalOpen: !isModalOpen,
      actualModal: actual,
      labelModal: label,
      status,
      certificateid: certificate.id,
    });
  };

  toggleLoading = () => {
    const { isLoading } = this.state;

    this.setState({
      isLoading: !isLoading,
      denialreason: "",
    });
  };

  toggleModal = () => {
    const { isModalOpen } = this.state;

    this.setState({
      isModalOpen: !isModalOpen,
    });
  };

  contentAprov = () => {
    return (
      <div className="d-flex justify-content-center w-100">
        <h2> Você tem certeza que deseja Aprovar ? </h2>
      </div>
    );
  };

  contentReprov = () => {
    const { denialreason } = this.state;

    return (
      <>
        <div className="d-flex justify-content-center w-100">
          <h2>
            {" "}
            Você tem certeza que deseja <Badge color="danger"> Reprovar </Badge> ?{" "}
          </h2>
        </div>

        <div className="d-flex mt-4 w-100">
          <Input
            placeholder="Motivo da reprovação, foi reprovado por...."
            rows="4"
            type="textarea"
            className="form-control-alternative"
            value={denialreason}
            onChange={(e) => this.setState({ denialreason: e.target.value })}
          />
        </div>
      </>
    );
  };

  contentModal = () => {
    const { actualModal } = this.state;

    if (actualModal === "Reprov") {
      return this.contentReprov();
    }

    return this.contentAprov();
  };

  render() {
    const { isModalOpen, labelModal } = this.state;
    const { certificates, crm, degree } = this.props;

    return (
      <>
        <ModalDoctor
          isOpen={isModalOpen}
          toggle={() => this.toggleModal()}
          content={this.contentModal()}
          label={labelModal}
          handlesubmit={() => this.handleSubmit()}
        />

        <Container className="mt-1" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <Table className="align-items-center table-hover" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col"> Nome </th>
                      <th scope="col"> Status </th>
                      <th scope="col" className="text-center">
                        {" "}
                        Arquivo{" "}
                      </th>
                      <th scope="col" className="text-center">
                        {" "}
                        Ações{" "}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <th scope="row">
                        <div className="d-flex align-items-center">
                          <span className="mr-2">{crm.hasOwnProperty("specialty") ? crm.specialty : "Não possui documento de conselho!"}</span>
                        </div>
                      </th>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className={`${crm.badges} font-size-badge`}>{crm.label_status}</span>
                        </div>
                      </td>

                      <td>
                        <div className="d-flex align-items-center d-flex justify-content-around">
                          {crm.path ? (
                            <>
                              <img src={ process.env.REACT_APP_BASE_URL_API + `/documents/${crm.path}`} alt="" height="65" width="70" className="border-radius" />

                              <a href={ process.env.REACT_APP_BASE_URL_API + `/documents/${crm.path}`} download={crm.path.split("/")[3]} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faDownload} size="2x" />
                              </a>
                            </>
                          ) : (
                            "Não possui path."
                          )}
                        </div>
                      </td>

                      <td className="text-center">
                        <div>
                          <Button className="btn btn-success" onClick={() => this.toggleModalContext("Aprov", "Aprovação", "3", crm)}>
                            Aprovar
                          </Button>

                          <Button className="btn btn-warning" onClick={() => this.toggleModalContext("Reprov", "Motivo Reprovação", "2", crm)}>
                            Reprovar
                          </Button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">
                        <div className="d-flex align-items-center">
                          <span className="mr-2">{degree && degree.hasOwnProperty("specialty") ? degree.specialty : "Não possui diploma!"}</span>
                        </div>
                      </th>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className={`${degree ? degree.badges : null} font-size-badge`}>{degree ? degree.label_status : ""}</span>
                        </div>
                      </td>

                      <td>
                        <div className="d-flex align-items-center d-flex justify-content-around">
                          {degree && degree.path ? (
                            <>
                              <img src={ process.env.REACT_APP_BASE_URL_API + `/documents/${degree.path}`} alt="" height="65" width="70" className="border-radius" />

                              <a href={ process.env.REACT_APP_BASE_URL_API + `/documents/${degree.path}`} download={degree.path.split("/")[3]} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faDownload} size="2x" />
                              </a>
                            </>
                          ) : (
                            "Não possui path."
                          )}
                        </div>
                      </td>

                      <td className="text-center">
                        <div>
                          <Button className="btn btn-success" onClick={() => this.toggleModalContext("Aprov", "Aprovação", "3", degree)}>
                            Aprovar
                          </Button>

                          <Button className="btn btn-warning" onClick={() => this.toggleModalContext("Reprov", "Motivo Reprovação", "2", degree)}>
                            Reprovar
                          </Button>
                        </div>
                      </td>
                    </tr>

                    {this.certificates(certificates)}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default tableListCertificates;
