import React from 'react'
import Header from "components/Headers/Header.jsx";
import { Container } from "reactstrap";
import PanelSetAmbassador from './painel/PanelSetAmbassador.jsx'


class createAmbassador extends React.Component{
    state = {}
    render() {
        return(
            <>
                <Header />

                <Container className="mt--7" fluid>
                    <PanelSetAmbassador />
                </Container>
            </>
        );
    }
}

export default createAmbassador;