export const STATUS_NOT_FINISHED = 0; // Aguardando atualização de status
export const STATUS_AUTHORIZED = 1; // Pagamento apto a ser capturado ou definido como pago
export const STATUS_PAYMENT_CONFIRMED = 2; // Pagamento confirmado e finalizado
export const STATUS_DENIED = 3; // Pagamento negado por Autorizador
export const STATUS_VOIDED = 10; // Pagamento cancelado
export const STATUS_REFUNDED = 11; // Pagamento cancelado após 23:59 do dia de autorização
export const STATUS_PENDING = 12; // Aguardando Status de instituição financeira
export const STATUS_ABORTED = 13; // Pagamento cancelado por falha no processamento ou por ação do AF
export const STATUS_SCHEDULED = 20; // Recorrência agendada

const statusWithMessage = [
  {
    id: 0,
    message: "Aguardando atualização de status",
  },
  {
    id: 1,
    message: "Pagamento apto a ser capturado ou definido como pago",
  },
  {
    id: 2,
    message: "Pagamento confirmado e finalizado",
  },
  {
    id: 3,
    message: "Pagamento negado por Autorizador",
  },
  {
    id: 10,
    message: "Pagamento cancelado",
  },
  {
    id: 11,
    message: "Pagamento cancelado após 23:59 do dia de autorização",
  },
  {
    id: 12,
    message: "Aguardando Status de instituição financeira",
  },
  {
    id: 13,
    message:
      "Pagamento cancelado por falha no processamento ou por ação do AF(Antifraude)",
  },
  {
    id: 20,
    message: "Recorrência agendada",
  },
];

const fraudErros = [
  {
    id: "A",
    Message:
      "Mudança de endereço excessiva. O comprador mudou o endereço de cobrança duas ou mais vezes nos últimos seis meses",
  },
  {
    id: "B",
    Message:
      "BIN do cartão ou autorização de risco. Os fatores de risco estão relacionados com BIN de cartão de crédito e/ou verificações de autorização do cartão",
  },
  {
    id: "C",
    Message:
      "Elevado números de cartões de créditos. O comprador tem usado mais de seis números de cartões de créditos nos últimos seis meses",
  },
  {
    id: "D",
    Message:
      "Impacto do endereço de e-mail. O comprador usa um provedor de e-mail gratuito ou o endereço de email é arriscado",
  },
  {
    id: "E",
    Message: "Lista positiva. O comprador está na sua lista positiva",
  },
  {
    id: "F",
    Message:
      "Lista negativa. O número da conta, endereço, endereço de e-mail ou endereço IP para este fim aparece sua lista negativa",
  },
  {
    id: "G",
    Message:
      "Inconsistências de geolocalização. O domínio do comprador de e-mail, número de telefone, endereço de cobrança, endereço de envio ou endereço IP é suspeito",
  },
  {
    id: "H",
    Message:
      "Excessivas mudanças de nome. O comprador mudou o nome duas ou mais vezes nos últimos seis meses",
  },
  {
    id: "I",
    Message:
      "Inconsistências de internet. O endereço IP e de domínio de e-mail não são consistentes com o endereço de cobrança",
  },
  {
    id: "N",
    Message:
      "Entrada sem sentido. O nome do comprador e os campos de endereço contém palavras sem sentido ou idioma",
  },
  {
    id: "O",
    Message: "Obscenidades. Dados do comprador contém palavras obscenas",
  },
  {
    id: "P",
    Message:
      "identidade morphing. Vários valores de um elemento de identidade estão ligados a um valor de um elemento de identidade diferentes. Por exemplo, vários números de telefones estão ligados a um número de conta única",
  },
  {
    id: "Q",
    Message:
      "Inconsistências do telefone. O número de telefone do comprador é suspeito",
  },
  {
    id: "R",
    Message:
      "Ordem arriscada. A transação, o comprador e o lojista mostram informações correlacionadas de alto risco",
  },
  {
    id: "T",
    Message:
      "Cobertura Time. O comprador está a tentar uma compra fora do horário esperado",
  },
  {
    id: "U",
    Message:
      "Endereço não verificável. O endereço de cobrança ou de entrega não pode ser verificado",
  },
  {
    id: "V",
    Message: "O cartão foi usado muitas vezes nos últimos 15 minutos",
  },
  {
    id: "W",
    Message:
      "Marcado como suspeito. O endereço de cobrança ou de entrega é semelhante a um endereço previamente marcado como suspeito",
  },
  {
    id: "Y",
    Message:
      "O endereço, cidade, estado ou país dos endereços de cobrança e entrega não se correlacionam",
  },
  {
    id: "Z",
    Message:
      "Valor inválido. Como a solicitação contém um valor inesperado, um valor padrão foi substituído. Embora a transação ainda possa ser processada, examinar o pedido com cuidado para detectar anomalias",
  },
  {
    id: "100",
    Message: "Operação bem sucedida.",
  },
  {
    id: "101",
    Message: "O pedido está faltando um ou mais campos necessários.",
  },
  {
    id: "102",
    Message: "Um ou mais campos do pedido contêm dados inválidos.",
  },
  {
    id: "150",
    Message:
      "Falha no sistema geral,Aguarde alguns minutos e tente reenviar o pedido.",
  },
  {
    id: "151",
    Message:
      "Este erro não inclui time-out entre o cliente e o servidor. Possível ação: ",
  },
  {
    id: "152",
    Message:
      "O pedido foi recebido, mas ocorreu time-out. Aguarde alguns minutos e reenviar o pedido.",
  },
  {
    id: "202",
    Message:
      "Prevenção à Fraude recusou o pedido porque o cartão expirou. Você também pode receber este código se a data de validade não coincidir com a data em arquivo do banco emissor.",
  },
  {
    id: "231",
    Message:
      "O número da conta é inválido. Solicite um cartão ou outra forma de pagamento.",
  },
  {
    id: "234",
    Message:
      "Há um problema com a configuração do comerciante. Entre em contato com o Suporte ao Cliente para corrigir o problema de configuração.",
  },
  {
    id: "400",
    Message: "A pontuação de fraude ultrapassa o seu limite.",
  },
  {
    id: "480",
    Message: "O pedido foi marcado para revisão pelo Gerenciador de Decisão.",
  },
  {
    id: "481",
    Message: "O pedido foi rejeitado pelo Gerenciador de Decisão.",
  },
];

export const getMessageStatus = (status) => {
  const error = statusWithMessage.filter((i) => i.id === status);

  if (error.length <= 0) return "status desconhecido.";

  return error[0].message;
};

export const badgeStatus = (status) => {
  let [badge, label] = "";

  status = parseInt(status);

  switch (status) {
    case 13:
      badge = "danger";
      label = "Erro no pagamento.";
      break;
    case 10:
      badge = "danger";
      label = "Pagamento cancelado.";
      break;
    case 11:
      badge = "danger";
      label = "Pagamento cancelado.";
      break;
    case 2:
      badge = "success";
      label = "Pagamento confirmado.";
      break;
    case 1:
      badge = "success";
      label = "Pagamento confirmado.";
      break;
    case 0:
      badge = "warning";
      label = "Status desconhecido.";
      break;

    default:
      badge = "danger";
      label = "Erro no pagamento.";
      break;
  }

  return {
    badge,
    label,
  };
};

export const getMessageStatusFraude = ({
  ReplyData: { FactorCode },
  FraudAnalysisReasonCode,
}) => {
  const status = FactorCode || FraudAnalysisReasonCode;
  const error = fraudErros.filter((i) => i.id === status);

  if (error.length <= 0) return "status desconhecido.";

  return error[0].Message;
};
