import React from "react";
import { Card, Container, Row, } from "reactstrap";

import api from "../../../../services/api";
// import logo from "../../../../assets/img/theme/loading.gif";
// import Alert from "reactstrap/lib/Alert";

const AmbassadorType = {
  MEDICO: 2,
  PACIENTE: 1,
};

class PanelSetAmbassador extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      PAS: [],
      PAC: [],
      selectPacOrPas: 0, // paciente = 1, médico = 2
      selectedAmbassador: null,
      inserts: [],
    };
  }

  async componentDidMount() {
    await this.getAmbassador();
  }

  getAmbassador = async () => {
    this.setState({ isLoading: true });

    const { data } = await api.get("ambassador/pasAndPac", {});

    await this.setState({
      PAS: data.doctors,
      PAC: data.patients,
    });
  };

  // Insert = (type, embaixador) => {
  //   const { inserts } = this.state;
  //   const data = {
  //     type,
  //     embaixador,
  //   };
  //   inserts.push(data);
  //   // this.setState({ inserts: inserts });
  // };

  onSetAmbassador = (e) => {
    const ambassadorId = +e.target.value;
    const { selectPacOrPas, PAC, PAS, inserts } = this.state;

    const ambassador = selectPacOrPas === AmbassadorType.MEDICO ? PAS.find((p) => p.id === ambassadorId) : PAC.find((p) => p.id === ambassadorId);

    const insertType = selectPacOrPas !== 0 ? selectPacOrPas === 1 ? selectPacOrPas : 0 : null;

    const alreadyExist = inserts.find((p) => p.embaixador.id === ambassador.id && p.embaixador.name === ambassador.name);

    if (alreadyExist) {
      console.log("Esse embaixador já está na lista");
      return;
    }
    
    this.setState({
      selectedAmbassador: ambassadorId,
      inserts: [...this.state.inserts, { type: insertType, embaixador: ambassador }],
    });
  };

  Delete = (index) => {
    const { inserts } = this.state;
    inserts.splice(index, 1);
    this.setState({ inserts: inserts });
  };

  onSubmit = async () => {
    const { inserts } = this.state;
    if (inserts.length > 0) {
      const data = await api.post("ambassador/create", { Ambassadors: inserts });

      if (data.status === 200) {
        alert("Embaixadores selecionados foram Nomeados");
        this.setState({ inserts: [] });
      }
    }
  };

  ambassadorType = () => {
    const { selectPacOrPas, PAS, PAC } = this.state;
    return selectPacOrPas !== 0
      ? selectPacOrPas === 2
        ? PAS.map((item) => {
            return (
              <option
                key={item.id}
                value={item.id}
              >
                {item.name}
              </option>
            );
          })
        : PAC.map((item) => {
            return (
              <option
                key={item.id}
                value={item.id}
              >
                {item.name}
              </option>
            );
          })
      : null;
  };

  selectTypeAmbassador = (selectPacOrPas) => {
    selectPacOrPas === "Paciente"
      ? this.setState({ selectPacOrPas: 1 })
      : selectPacOrPas !== "Selecione o tipo"
      ? this.setState({ selectPacOrPas: 2 })
      : this.setState({ selectPacOrPas: 0 });
  };
  
  render() {
    console.log(this.state.inserts);
    // const avatar = `https://mdbhomolog.soterotech.net/documents/default_avatar.png`;
    const avatar = process.env.REACT_APP_BASE_URL_API + `/documents/default_avatar.png`;

    return (
      <>
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <div className="container-set-ambassador">
                  <div className="body-set-ambassador">
                    <select
                      className="form-control select-ambassador"
                      onChange={(e) => {
                        this.selectTypeAmbassador(e.target.value);
                      }}
                    >
                      <option defaultValue>Selecione o tipo</option>
                      <option>Médico e PAS</option>
                      <option>Paciente</option>
                    </select>
                    <select
                      onChange={this.onSetAmbassador}
                      value={this.state.selectedAmbassador}
                      className="form-control select-ambassador"
                      placeholder="Selecione o tipo"
                    >
                      <option defaultValue>Selecione o Embaixador</option>
                      {this.ambassadorType()}
                    </select>
                  </div>
                  <div className="body-set-ambassador">
                    {this.state.inserts.length > 0 ? (
                      this.state.inserts.map((item, index) => {
                        return (
                          <div className="container-new-ambassador" key={index}>
                            <div className="body-new-ambassador">
                              <img alt="Minha foto de perfil" className="image-new-ambassador" src={avatar} />
                              <div>{item.embaixador.name}</div>
                            </div>
                            <button
                              className="remove-new-ambassador"
                              onClick={() => {
                                this.Delete(index);
                              }}
                            >
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        );
                      })
                    ) : (
                      <div className="container-new-ambassador">Nenhum embaixador selecionado.</div>
                    )}
                    <div className="submit-embassador">
                      <button
                        className="btn btn-success"
                        onClick={() => {
                          this.onSubmit();
                        }}
                      >
                        Nomear
                      </button>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default PanelSetAmbassador;
