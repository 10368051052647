import React from "react";
import { Container } from "reactstrap";
import Header from "components/Headers/Header.jsx";
import TableDoctors from "./pages/doctor/table/tableListDoctors";

class Index extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1"
  };
  
  render() {
    return (
      <>
        <Header />

        <Container className="mt--7" fluid>
          <TableDoctors />
        </Container>
      </>
    );
  }
}

export default Index;