import React from "react";
import { Container } from "reactstrap";
import Header from "components/Headers/Header.jsx";
import TablePayments from "../payment/table/tableListPayments.jsx";

class Index extends React.Component {  
  render() {
    return (
      <>
        <Header />

        <Container className="mt--7" fluid>
          <TablePayments />
        </Container>
      </>
    );
  }
}

export default Index;