import React from "react";
import Header from "components/Headers/Header.jsx";
import { Container } from "reactstrap";
import ListEmbassador from "./table/ListEmbassador.jsx";

class informationAmbassador extends React.Component {
  state = {};
  render() {
    return (
      <>
        <Header />

        <Container className="mt--7" fluid>
          <ListEmbassador />
        </Container>
      </>
    );
  }
}

export default informationAmbassador;
